// routes
import { PATH_PAGE } from "../../../routes/paths";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Accueil",
    icon: <Iconify icon="eva:home-fill" />,
    path: "/",
  },
  {
    title: "À propos",
    icon: <Iconify icon="ic:round-grain" />,
    path: PATH_PAGE.about,
  },
  {
    title: "FAQs",
    icon: <Iconify icon="eva:book-open-fill" />,
    path: PATH_PAGE.faq,
  },
  // {
  //   title: 'Nos biens',
  //   icon: <Iconify icon="eva:book-open-fill" />,
  //   path: PATH_APP.eCommerce.shop,
  // },
];

export default navConfig;
