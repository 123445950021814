import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layouts/main/MainLayout";
import {
  // EcommerceShopPage,
  // EcommerceProductDetailsPage,
  Page404,
  AboutUs,
  Contact,
  Faq,
  HomePage,
  Terms,
  // MemberAccess,
  // GeneralEcommercePage,
  // UserListPage,
  // InvoiceListPage,
  LoginPage,
  RegisterPage,
} from "./elements";

// config
// import { PATH_AFTER_LOGIN } from "../config";
// import DashboardLayout from "src/layouts/dashboard/DashboardLayout";
// import AuthGuard from "src/auth/AuthGuard";
import GuestGuard from "src/auth/GuestGuard";
import CompactLayout from "src/layouts/compact";

export default function Router() {
  return useRoutes([
    // // Auth
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <LoginPage /> },
        // { path: 'register-unprotected', element: <RegisterPage /> },
        // {
        //   element: <CompactLayout />,
        //   children: [
        //     { path: 'reset-password', element: <ResetPasswordPage /> },
        //     { path: 'new-password', element: <NewPasswordPage /> },
        //     { path: 'verify', element: <VerifyCodePage /> },
        //   ],
        // },
      ],
    },

    // App
    // {
    //   path: "app",
    //   element: (
    //     // <AuthGuard>
    //     <MainLayout />
    //     // </AuthGuard>
    //   ),
    //   children: [
    //     {
    //       element: (
    //         <Navigate /*to={PATH_AFTER_LOGIN}*/ to="/app/e-commerce/shop" />
    //       ),
    //       index: true,
    //     },
    //     {
    //       path: "e-commerce",
    //       children: [
    //         {
    //           element: <Navigate to="/app/e-commerce/shop" replace />,
    //           index: true,
    //         },
    //         { path: "shop", element: <EcommerceShopPage /> },
    //         { path: "product/:name", element: <EcommerceProductDetailsPage /> },
    //       ],
    //     },
    //     {
    //       path: "user",
    //       children: [
    //         {
    //           element: <Navigate to="/app/user/profile" replace />,
    //           index: true,
    //         },
    //       ],
    //     },
    //   ],
    // },

    // Dashboard
    // {
    //   path: "dashboard",
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     // { path: 'app', element: <GeneralAppPage /> },
    //     { path: "ecommerce", element: <GeneralEcommercePage /> },
    //     // { path: 'analytics', element: <GeneralAnalyticsPage /> },
    //     // { path: 'banking', element: <GeneralBankingPage /> },
    //     // { path: 'booking', element: <GeneralBookingPage /> },
    //     // { path: 'file', element: <GeneralFilePage /> },
    //     {
    //       path: "e-commerce",
    //       children: [
    //         {
    //           element: <Navigate to="/dashboard/e-commerce/shop" replace />,
    //           index: true,
    //         },
    //         { path: "shop", element: <EcommerceShopPage /> },
    //         { path: "product/:name", element: <EcommerceProductDetailsPage /> },
    //         // { path: 'list', element: <EcommerceProductListPage /> },
    //         // { path: 'product/new', element: <EcommerceProductCreatePage /> },
    //         // { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
    //         // { path: 'checkout', element: <EcommerceCheckoutPage /> },
    //       ],
    //     },
    //     {
    //       path: "user",
    //       children: [
    //         {
    //           element: <Navigate to="/dashboard/user/list" replace />,
    //           index: true,
    //         },
    //         // { path: 'profile', element: <UserProfilePage /> },
    //         // { path: 'cards', element: <UserCardsPage /> },
    //         { path: "list", element: <UserListPage /> },
    //         // { path: 'new', element: <UserCreatePage /> },
    //         // { path: ':name/edit', element: <UserEditPage /> },
    //         // { path: 'account', element: <UserAccountPage /> },
    //       ],
    //     },
    //     {
    //       path: "invoice",
    //       children: [
    //         {
    //           element: <Navigate to="/dashboard/invoice/list" replace />,
    //           index: true,
    //         },
    //         { path: "list", element: <InvoiceListPage /> },
    //         // { path: ':id', element: <InvoiceDetailsPage /> },
    //         // { path: ':id/edit', element: <InvoiceEditPage /> },
    //         // { path: 'new', element: <InvoiceCreatePage /> },
    //       ],
    //     },
    //     // {
    //     //   path: 'blog',
    //     //   children: [
    //     //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
    //     //     { path: 'posts', element: <BlogPostsPage /> },
    //     //     { path: 'post/:title', element: <BlogPostPage /> },
    //     //     { path: 'new', element: <BlogNewPostPage /> },
    //     //   ],
    //     // },
    //     // { path: 'files-manager', element: <FileManagerPage /> },
    //     // {
    //     //   path: 'mail',
    //     //   children: [
    //     //     { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
    //     //     { path: 'label/:customLabel', element: <MailPage /> },
    //     //     { path: 'label/:customLabel/:mailId', element: <MailPage /> },
    //     //     { path: ':systemLabel', element: <MailPage /> },
    //     //     { path: ':systemLabel/:mailId', element: <MailPage /> },
    //     //   ],
    //     // },
    //     // {
    //     //   path: 'chat',
    //     //   children: [
    //     //     { element: <ChatPage />, index: true },
    //     //     { path: 'new', element: <ChatPage /> },
    //     //     { path: ':conversationKey', element: <ChatPage /> },
    //     //   ],
    //     // },
    //     // { path: 'calendar', element: <CalendarPage /> },
    //     // { path: 'kanban', element: <KanbanPage /> },
    //     // { path: 'permission-denied', element: <PermissionDeniedPage /> },
    //     // { path: 'blank', element: <BlankPage /> },
    //   ],
    // },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "about-us", element: <AboutUs /> },
        { path: "contact", element: <Contact /> },
        { path: "faq", element: <Faq /> },
        { path: "terms", element: <Terms /> },
        // {
        //   path: "member-access",
        //   element: (
        //     <AuthGuard>
        //       <MemberAccess />
        //     </AuthGuard>
        //   ),
        // },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        // { path: '500', element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        // { path: '403', element: <Page403 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
//https://4.cdn.lesanciennes.com/cache/b/2/b2165e81de27f09f785728dbe72f06c3.jpg
