import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//
import Header from './Header';
import Footer from './Footer';
// ----------------------------------------------------------------------

export default function MainLayout() {

  return (
    <Box  sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          my: '25px',
          flexGrow: 1,
          ...( {
            pt: { xs: 8, md: 11 },
          }),
        }}
      >
        <Outlet />
      </Box>

      <Footer />
    </Box>
  );
}
