// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
// import ThemeLocalization from './locales';
// components
import { StyledChart } from "./components/chart";
// import SnackbarProvider from './components/snackbar';
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";

function App() {
  return (
    <div>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <StyledChart />
            <Router />
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </div>
  );
}

export default App;
