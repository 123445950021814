import { Link as RouterLink, useLocation } from "react-router-dom";
// @mui
import {
  Box,
  Grid,
  Link,
  Stack,
  Divider,
  Container,
  Typography,
  IconButton,
} from "@mui/material";
// routes
import { PATH_PAGE } from "../../routes/paths";
// _mock
import { _socials } from "../../_mock/arrays";
// components
import Logo from "../../components/logo";
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: "Collectionnable",
    children: [
      { name: "À propos", href: PATH_PAGE.about },
      { name: "Contact", href: PATH_PAGE.contact },
      { name: "FAQs", href: PATH_PAGE.faq },
    ],
  },
  {
    headline: "Legal",
    children: [
      { name: "Termes et Conditions", href: "#" },
      { name: "Politique de confidentialité", href: "#" },
      // { name: "Dashboard", href: "/dashboard" },
    ],
  },
  {
    headline: "Contact",
    children: [
      {
        name: "contact@collectionnable.io",
        href: "#",
      },
      {
        name: "06.01.71.61.78",
        href: "#",
      },
      { name: "6 rue Olof Palme; 92110 Clichy France", href: "#" },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();

  const isHome = pathname === "/";

  // const simpleFooter = (
  //   <Box
  //     component="footer"
  //     sx={{
  //       py: 5,
  //       textAlign: 'center',
  //       position: 'relative',
  //       bgcolor: 'background.default',
  //     }}
  //   >
  //     <Container>
  //       <Logo sx={{ mb: 1, mx: 'auto' }} />

  //       <Typography variant="caption" component="div">
  //         © All rights reserved
  //         <br /> made by &nbsp;
  //         <Link href="https://minimals.cc/"> minimals.cc </Link>
  //       </Typography>
  //     </Container>
  //   </Box>
  // );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: "relative",
        bgcolor: "background.default",
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
          sx={{
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Au-delà d'un simple projet, qui favorise les échanges de
              l’expérience et du savoir, notre but est de soutenir et de
              participer activement à la conservation et au partage de notre
              patrimoine collectif, industriel et artistique aussi glorieux que
              méconnu.
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton key={social.name}>
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: "column", md: "row" }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: "center", md: "flex-start" }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      to={link.href}
                      component={RouterLink}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          © 2021. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return isHome ? mainFooter : mainFooter;
}
