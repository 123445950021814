import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/LoginPage"))
);
export const RegisterPage = Loadable(
  lazy(() => import("../pages/auth/RegisterPage"))
);

// App: ECOMMERCE
// export const EcommerceShopPage = Loadable(
//   lazy(() => import("../pages/app/EcommerceShopPage"))
// );
// export const EcommerceProductDetailsPage = Loadable(
//   lazy(() => import("../pages/app/EcommerceProductDetailsPage"))
// );

// DASHBOARD: GENERAL
export const GeneralEcommercePage = Loadable(
  lazy(() => import("../pages/dashboard/GeneralEcommercePage"))
);

// DASHBOARD: USER
// export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));
// export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')));
export const UserListPage = Loadable(
  lazy(() => import("../pages/dashboard/UserListPage"))
);

// DASHBOARD: INVOICE
export const InvoiceListPage = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceListPage"))
);

// MAIN
// export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
// export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
export const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
export const Faq = Loadable(lazy(() => import("../pages/Faq")));
export const AboutUs = Loadable(lazy(() => import("../pages/AboutPage")));
export const Contact = Loadable(lazy(() => import("../pages/Contact")));
export const Terms = Loadable(lazy(() => import("../pages/Terms")));
// export const MemberAccess = Loadable(
//   lazy(() => import("../pages/MemberAccess"))
// );
// export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
// export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
